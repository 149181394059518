export const environment = {
  recaptchaSiteKey:"6LduiA4qAAAAADZwlHk0sPq2yMJi1UWRNOXPnpYb",

  basePath: '',

  currency: 'inr',
  production: true,
  isLicence: false,
  use: function () {
    var currency = localStorage.getItem('currency');
    var isLicence = localStorage.getItem('licence');
    if (currency)
      this.currency = currency;
    // if (isLicence)
    //   this.isLicence = isLicence;
  }
};
environment.use();
