import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import io from 'socket.io-client';  // Correct default import
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketServiceService {
  private socket: any;  // Use any if TypeScript types are missing

  constructor() {
    // Initialize Sentry first
    Sentry.init({
      dsn: "https://ac4f39f44b1cb6a10ae5649c612f8b16@o4507813860671488.ingest.us.sentry.io/4507813863948288",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 1.0,
      beforeSend(event) {
        // Exclude events related to socket connections
        if (event.request?.url?.includes(environment.basePath)) {
          return null;
        }
        return event;
      },
      autoSessionTracking: false,
      debug: false,
    });

    // Now initialize the socket connection
    this.initializeSocket();
  }

  private initializeSocket() {
    this.socket = io(environment.basePath, {
      transports: ['websocket'],
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
    });

    this.socket.on('connect', () => {
      console.log('Socket connected');
      this.joinCompanyRoom();
    });

    this.socket.on('error', (error: any) => {
      console.error('Socket error:', error);
    });
  }

  private joinCompanyRoom() {
    const companyIdStr = localStorage.getItem('company_id');
    if (companyIdStr !== null) {
      const company_id: number = parseInt(companyIdStr, 10);
      if (!isNaN(company_id)) {
        this.socket.emit('join', { company_id });
      } else {
        console.error('Socket Err: Invalid company_id value in localStorage');
      }
    } else {
      console.error('Socket Err: company_id not found in localStorage');
    }
  }

  emit(eventName: string, data: any): void {
    this.socket.emit(eventName, data);
  }

  on(eventName: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(eventName, (data: any) => {
        observer.next(data);
      });
    });
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  checkConnectionStatus(): boolean {
    return this.socket.connected;
  }
}
