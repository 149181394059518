import {
  Component,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
  ComponentFactoryResolver,
  SimpleChanges,
} from "@angular/core";
import { of } from "rxjs";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatTabGroup } from "@angular/material/tabs";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { RstKotComponent } from "../rst-kot/rst-kot.component";
import { environment } from "../../../environments/environment";
import { SocketServiceService } from "../../socket-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ItemsDialogComponent } from "../items-dialog/items-dialog.component";
import { ReceiptDialogComponent } from "../receipt-dialog/receipt-dialog.component";
import { TabletransferDialogComponent } from "../tabletransfer-dialog/tabletransfer-dialog.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component"; // Import the dialog component
import { CacheServiceService } from "../../cache-service.service";
import { TaxSummaryService } from "../../tax-summary.service";
import { SharedModule } from "../../common.module";
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { Notyf } from "notyf";
import { CategoryCartComponent } from "../../../stories/stories-module/category-cart/category-cart.component";
import { CategoryCardButtonComponent } from "../../../stories/stories-module/category-card-button/category-card-button.component";
import { SubCategoryCardComponent } from "../../../stories/stories-module/sub-category-card/sub-category-card.component";
import { KotListComponent } from "../../../stories/stories-module/kot-list/kot-list.component";
import { DineInComponent } from "./dine-in/dine-in.component";
import { TakeAwayComponent } from "./take-away/take-away.component";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: "app-rst-invoice",
  templateUrl: "./rst-invoice.component.html",
  styleUrls: ["./rst-invoice.component.css"],
  standalone: true,
  imports: [
    SharedModule,
    CategoryCartComponent,
    CategoryCardButtonComponent,
    SubCategoryCardComponent,
    KotListComponent,
    DineInComponent,
    TakeAwayComponent
  ],
})
export class RstInvoiceComponent implements OnInit {
  categoryObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  isEditMode = false;
  editContactId: number | null = null;
  suggestions: any[] = [];
  listNumber: any[] = [];
  inputValue: string = "";
  selectedNumber: string = "";
  pendingInvoice: boolean = false;
  isEnterKeyHandling: boolean = false;
  showBackButton: any = "false";
  errorMessage: string | null = null;
  showPopup = false;
  kotList: any[] = [];
  orderList: any[] = [];
  show_allow_login: boolean = false;
  allow_login: boolean = false;
  isQtyFocused: boolean = false;
  isdiscountFocused: boolean = false;
  table_id: any;
  table_name: any;
  tableObj: any;
  currency: any;
  kotArr: any = [];
  KOT_data: any = [];
  itemsArr: any = [];
  model: any = {};
  select_all: boolean = true;
  @ViewChild("f") f: any;
  sgst: any = [];
  cgst: any = [];
  igst: any = [];
  customer_item_rate_applicable: boolean = false;
  use_qty_from_sale_history: boolean = false;
  @ViewChild("s_rate1") s_rate1!: ElementRef;
  @ViewChild("qty1") qty1!: ElementRef;
  @ViewChild("sitm") sitm!: ElementRef;
  @ViewChild("tabGroup", { static: false }) tabGroup!: MatTabGroup;
  width: any = window.innerWidth;
  // invoice_no: any;
  contractObj: any;
  ledgerObj: any;
  ledger_state: any;
  items: any = [];
  isAutoFocus: boolean = false;
  refund_amt: any = 0;
  tax: any = [];
  totalAmt: any;
  amount: any;
  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  roundOffSettingVal: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  _generating_sales_order: boolean = false;

  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<any[]> = new Observable<any>();
  itemObj: any = [];
  allowChangeRate: boolean = false;
  mapArr: any;
  taxSlabList: any;
  checkEnableResturantFlag: boolean = false;
  catArr: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  itemObject: MatTableDataSource<any> = new MatTableDataSource<any>();
  searchText: any;
  activeType: any = false;
  imagedata: any;
  itemArr: any;
  items_data: any = [];
  data: any;
  isTransactionGSTslab: boolean = false;
  isServiceCharge: boolean = false;
  serviceChargeValue: any = 0;
  transaction_details: any;
  items_invoice: any = [];
  company: any;
  isCash: boolean = true;
  isSOcount: boolean = false;
  so_count: any;
  isDOcount: boolean = false;
  do_count: any;
  customerRates: any;
  isCredit: any;
  stateName: any;
  place_of_supply = new FormControl();
  sbilling_state = new FormControl();
  shipping_state = new FormControl();
  totalamt: any;
  invoice_arr: any = [];
  webappActiveTable: boolean = false;
  closing_balance1: any;
  closing_balance2: any;
  totalAmtReadonlyFlg: boolean = false;
  receiptdata: any;
  dueInvoice: any;
  displayedColumns = [
    "customer_name",
    "display_trans_no",
    "net_amount",
    "advance",
  ];
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  tableDetails: any;
  selectedTable: any;
  take_away = true;
  dine_in = true;
  water: boolean = false;
  waiter: boolean = false;
  notesValueForEdit: any;
  disposalItemData: any;
  disposallist: any;
  disposalItemlist: any;
  disposalItemToggle = false;
  showValidationInput = false;
  showValidationDiscount = false;
  otpVerified = false;
  otpInvalid = false;
  non_receivable = false;
  formSubmitted = false;
  order_tab: any = "Dine-In";
  sub_category_data: any;
  subCatArr = [];
  getItems: boolean = false;
  getCategory: boolean = false;
  getSubCategory: boolean = false;
  getDisposalitems: boolean = false;
  getDisposalCategory: boolean = false;
  isLocal: boolean = false;
  tableInfo: any;
  tableCapacity: any = 0;
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariant: any[] = [];
  checkedVariantAmount: number = 0;
  connection: any;
  selectedCategoryName: any = null;
  menuItemsWithPriceList: any = [];
  isEbillingEnable: any = "false";
  hasPriceList: any = null;
  waitforinvoice: boolean = false;
  waitforkot: boolean = false;
  waitforTakeaway: boolean = false;
  kot_Dta: any = [];

  constructor(
    private apiService: RstDashboardService,
    private _Activatedroute: ActivatedRoute,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    private cacheService: CacheServiceService,

  ) { }
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableInfo'] && changes['tableInfo'].currentValue) {
      this.initializeTableData(this.tableInfo); // Custom logic
    }
  }
  initializeTableData(tableInfo: any): void {
  }
  
/**
 * Lifecycle hook that initializes the RST invoice component
 * 
 * This method performs the following operations:
 * 1. Establishes socket connection
 * 2. Loads initial data
 * 3. Sets up a subscription to route parameters to handle table ID changes
 * 
 * When a table ID is present in the route parameters:
 * - Converts the ID to a number
 * - Updates the table_id property
 * - Triggers a data load operation
 * 
 * @implements OnInit
 * @returns void
 */
ngOnInit(): void {
    this.connectSocket();  // Initialize socket connection
    // Subscribe to route parameter changes
    this._Activatedroute.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id) {
        this.table_id = +id;     // Convert string ID to number
        this.tableInfo = this.load()  // Load table data
      }
    });
}


  @ViewChild(DineInComponent) DinechildComponent!: DineInComponent;
  // reset all data
  reset() {
    if (this.DinechildComponent) {
      this.DinechildComponent.resetData();
      this.DinechildComponent.initializeFormGroup() // Pass new data to child
    }
    // this.load();
  }

  @ViewChild(TakeAwayComponent) TakechildComponent!: TakeAwayComponent;
  // reset all data
  resetTakeaway() {
    if (this.TakechildComponent) {
      this.TakechildComponent.initializeFormGroup() // Pass new data to child
    }
    // this.load();
  }

  handleReset(eventData: any) {
    // Handle reset request triggered by the child
    this.load(); // Optional: Call the parent reset logic
  }


  ngOnDestroy() {
    this.connection.unsubscribe();
  }

  /**
   * Handles the focus state of a quantity input field
   * 
   * Updates the quantity focus state based on the event received. Sets 
   * isQtyFocused to true when the field gains focus and false when it
   * loses focus.
   * 
   * @param e - The focus/blur event object
   * @returns void
   * 
   * @example
   * // HTML usage:
   * <input (focus)="onQtyFocus($event)" (blur)="onQtyFocus(null)">
   */
  onQtyFocus(e: any): void {
      if (e) {
        this.isQtyFocused = true;
      } else {
        this.isQtyFocused = false;
      }
  }
  
  redirectToKeyboard() {

  }

  /**
   * Resets all OTP-related fields to their default states
   * 
   * This method resets the following states:
   * - OTP verification status
   * - OTP validation error state
   * - Validation discount visibility
   * 
   * @returns void
   * 
   * @example
   * // Usage:
   * this.resetOTPFields();
   */
  resetOTPFields(): void {
      this.otpVerified = false;          // Reset OTP verification status
      this.otpInvalid = false;           // Clear OTP validation error state
      this.showValidationDiscount = false; // Hide validation discount
  }

/**
 * Processes a list of categories and filters for restaurant menu items
 * 
 * This method performs two main operations:
 * 1. Filters the input array to include only categories marked as restaurant_menu_items
 * 2. For each filtered category, checks for cached images and sets the image URL
 * 
 * @param result - Array of category objects to process
 * @returns void
 * 
 * @example
 * const categories = [
 *   { category_id: 1, restaurant_menu_items: true },
 *   { category_id: 2, restaurant_menu_items: false }
 * ];
 * this.processCategoryList(categories);
 */
processCategoryList(result: any): void {
    // Filter categories to include only restaurant menu items
    this.catArr = result.filter(
        (category: any) => category.restaurant_menu_items == true
    );

    // Process each category for cached images
    this.catArr.forEach((category: any) => {
        const cachedImage = this.cacheService.get(
            `categoryImage_${category.category_id}`
        );
        if (cachedImage) {
            this.setImageUrl(category, cachedImage);
        }
    });
}


  setImageUrl(category: any, url: string): void {
    category.imageurl = url;
  }

  connectSocket() {
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
          // get table for the transaction.
          this.apiService.getTableByTransId(result.transaction_id).subscribe(
            (res: any) => {

              if (
                res &&
                res[0] &&
                res[0].table_id &&
                res[0].table_id == this.table_id
              ) {
                this.reset();
                this.resetTakeaway();
              }
            },
            (error) => {
              console.log("refresh socket at rst invoice error", error);
            }
          );
        
      });
  }

  createImageFromBlob(image: Blob, fn: any) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        var reader_result = reader.result;
        fn(reader_result);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  load() {

    const cachedCategory = this.cacheService.get("categoryList");

    if (cachedCategory) {
      this.getCategory = false;
      this.catArr = cachedCategory;
      this.categoryObj = new MatTableDataSource(this.catArr);
      this.categoryObj.sort = this.sort;
      this.categoryObj.paginator = this.paginator;
      this.processCategoryList(cachedCategory);
    } else {
      this.getCategory = true;
    }

    //   //DisposalItemList--------------------------------------------------------
    const disposalItemList = this.cacheService.get("disposalItemList");
    if (disposalItemList) {
      this.getDisposalitems = false;
      this.disposalItemData = disposalItemList;
    } else {
      this.getDisposalitems = true;
    }
    //   //DisposalCategoryList--------------------------------------------------------
    const disposalCategoryList = this.cacheService.get("disposalCategoryList");
    if (disposalCategoryList) {
      this.getDisposalCategory = false;
      this.disposallist = disposalCategoryList;
    } else {
      this.getDisposalCategory = true;
    }

    this.tableInfoFun();
  }

  tableInfoFun() {
    var keyData = {
      roleid: localStorage.getItem("role_id"),
      key: "ChangeMRP",
    };
    const data = [];
    data.push({
      getItems: this.getItems,
      getCategory: this.getCategory,
      getSubCategory: this.getSubCategory,
      getDisposalitems: this.getDisposalitems,
      getDisposalCategory: this.getDisposalCategory,
      keyData: keyData,
    });
    // this.spinner.show();
    this.apiService.getTableInfo(this.table_id, data).subscribe(
      async (result: any) => {
        
        this.hasPriceList = result[0]?.hasPriceList || null;
        // this.spinner.hide();
        this.tableInfo = result[0];
        //---------allowChangeRate------------------
        this.allowChangeRate = this.tableInfo.allowChangeRate ? true : false;

        if (this.tableInfo.restaurantMenuCategory !== null) {
          if (this.tableInfo.restaurantMenuCategory.length) {
            this.catArr = this.tableInfo.restaurantMenuCategory;
            this.categoryObj = new MatTableDataSource(this.catArr);
            this.categoryObj.sort = this.sort;
            this.categoryObj.paginator = this.paginator;
            this.cacheService.set("categoryList", this.catArr);
          }
        }

        //---------tableName------------------
        this.table_name = this.tableInfo.tableName
          ? this.tableInfo.tableName
          : null;

          //---------tableCapacity------------------
        this.tableCapacity = this.tableInfo.tableCapacity
        ? this.tableInfo.tableCapacity
        : null;

        this.findAccSetting(this.tableInfo.accountMapList)

      })

  }

  handelerror(event: any) {
    if (event.target) {
      event.target.src = "/assets/images/NoImage.png";
    }
  }

  _filter(value: any) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item: any) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item: any) {
    return Item ? Item.item_name : "";
  }

  search(event: any) {
    // If backspace is pressed and the input is empty
    if (
      event.key === "Backspace" &&
      (!this.itemControl.value || this.itemControl.value?.length === 0)
    ) {
      // Clear the item data and related fields without focusing on the qty input field
      this.resetItemInput();
      return;
    }
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl && this.itemControl.value && this.itemControl.value.length > 0) {
        const filter = "restaurant_menu_items";
        this.apiService
          .getCustomFilterItemList(
            this.itemControl.value,
            filter,
            this.table_id
          )
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (result: any) => { }
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option && event.option.value != 0) {
      var item_id = event.option.value ? event.option.value.item_id : undefined;
      var indexofItem = this.itemObj.findIndex(
        (i: any) => i.item_id === item_id
      );

      if (
        (event.option.value.options && event.option.value.options.length) > 0 ||
        (event.option.value.variant_groups && event.option.value.variant_groups.length) > 0
      ) {
        const data = { item: this.itemObj[indexofItem], status: "add" };
        this.openCustomization(this.itemObj[indexofItem]);
      } else {
        const isCustomised = false;
        this.populateItemData(this.itemObj[indexofItem], isCustomised);
      }

      // Move focus to qty1 element after item selection
      setTimeout(() => {
        if (this.qty1 && this.qty1.nativeElement) {
          this.qty1.nativeElement.focus();
        }
      }, 100);
    }
  }
  clearInput() {
    this.itemControl.reset();
  }

  populateItemData(itemDetails: any, isCustomised: any) {
    this.model = itemDetails;
    const qty = itemDetails.qty ? itemDetails.qty : 1;
    const a = this.model.qty * this.model.s_rate;
    const b = this.model.qty * this.optionarray_amount;
    const amount = a + b;
    this.model.amount = amount ? amount : itemDetails.s_rate * qty;
    this.model.qty = qty;

    this.form.get("item_qty")?.setValue(this.model.qty);
    this.form.get("item_rate")?.setValue(this.model.s_rate);
    this.form.get("item_amt")?.setValue(this.model.amount);

    if (isCustomised) this.onAddItem();
  }

  focusSetting() {
    if (this.isAutoFocus && this.qty1) {
      this.qty1.nativeElement.focus();
    }
  }

  // In onAddItem method, add checks to differentiate sdfsdf between Take Away and Dine-In
  onAddItem() {

    if (this.order_tab === "Dine-In") {

      this.apiService.addItemToDineInCart(this.model);
    }

    if (this.order_tab === "Take Away") {

      this.apiService.addItemToTakeAwayCart(this.model);
    }

    this.resetItemInput();

  }

  private resetItemInput(): void {
    this.model = {}; // Clear the model
    this.form.get("item_qty")?.setValue(null); // Reset the quantity input
    this.form.get("item_rate")?.setValue(null); // Reset the rate input
    this.form.get("item_amt")?.setValue(null); // Reset the amount input
    this.itemControl.setValue("");
    setTimeout(() => {
      if (this.sitm.nativeElement) {
        this.sitm.nativeElement.focus(); // Refocus on the search input
      }
    }, 0);
  }

  // all accounts settings
  findAccSetting(map: any[]) {
    this.mapArr = map;
    
    // Handle Take Away Table setting
    const takeAway = this.mapArr.find((e: any) => e.key === "Take_Away_Table");
    if (takeAway?.template_value === this.table_id) {
      this.dine_in = false;
      this.order_tab = "Take Away";
    }
  
    // Handle Transaction GST slab setting
    const transactionGstSlab = this.mapArr.find((e: any) => e.key === "Transaction_gstslab");
    this.isTransactionGSTslab = transactionGstSlab?.checkbox_value || false;
  
    if (this.isTransactionGSTslab) {
      const gstSlabEntry = this.mapArr.find((i: any) => i.key === "gstslab_value");
      if (gstSlabEntry?.template_value) {
        this.form.get("taxslab")?.setValue(gstSlabEntry.template_value);
      }
    }
  
    // Handle Service Charge setting
    const serviceCharge = this.mapArr.find((e: any) => e.key === "service_charge");
    this.isServiceCharge = serviceCharge?.checkbox_value || false;
  
    if (this.isServiceCharge) {
      const serviceChargeEntry = this.mapArr.find(
        (i: any) => i.key === "service_charge_value"
      );
      
      if (serviceChargeEntry?.text_value) {
        this.serviceChargeValue = serviceChargeEntry.text_value;
        this.form.get("service_charge_per")?.setValue(serviceChargeEntry.text_value);
      }
    }
  
    // Handle Auto Focus setting
    const autoFocusEntry = this.mapArr.find(
      (i: any) => i.key === "Enable_Auto_Focus"
    );
    this.isAutoFocus = autoFocusEntry?.checkbox_value || false;
  }
  

  // main form for all transactions details
  form: FormGroup = new FormGroup({
    customer_name: new FormControl("Name"),
    email: new FormControl(null),
    address: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    cash: new FormControl(0, Validators.required),
    card: new FormControl(0, Validators.required),
    upi: new FormControl(0, Validators.required),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    taxslab: new FormControl(0),
    vattaxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    vat_per: new FormControl(0),
    vat_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    item_qty: new FormControl(null),
    item_rate: new FormControl(null),
    item_amt: new FormControl(null),
    cash_receive: new FormControl(0),
    upi_receive: new FormControl(0),
    instruction: new FormControl(""),
    service_charge: new FormControl(0),
    service_charge_per: new FormControl(0),
  });

  // on change cash card or upi
  onCashOrCard() {
    var total_advance =
      this.form?.value.cash + this.form.value.card + this.form.value.upi;
    var balance_amt = this.form.value.net_amount - total_advance;
    this.form.get("advance")?.setValue(total_advance);
    this.form.get("balance")?.setValue(balance_amt);
    if (this.form?.value.cash_receive >= this.form?.value.cash) {
      this.refund_amt = this.form.value.cash_receive - this.form.value.cash;
    }
  }

  // on change cash card or upi
  refreshPayment() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
    }
    this.onCashOrCard();
  }

  // on change cash card or upi
  onCashReceive() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
      this.onCashOrCard();
    }
  }

  // on change tax
  onTaxChange(e: any) {
  }

  //on focus at item search bar
  onFocus(e: any) {
    const filter = "restaurant_menu_items";
    this.apiService.getCustomItemList(filter).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => { }
    );
  }

  // to download pdf
  downloadClick(dataObj: any, transNo: any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
        if (this.order_tab === "Take Away") {
          // this.onKotAdd.emit(true);
          // this.SaveAndPrint();
        }
        if (this.order_tab === "Dine-In") {
          this._generating_sales_order = false;
          // this.panel3.open();
        }
      },
      (result: any) => { }
    );
  }


  getUniqueItems(itemsArr: any[]) {
    const uniqueItems: Map<string, any> = new Map();

    itemsArr.forEach(item => {
      // Create a unique identifier considering all relevant properties
      const uniqueKey = JSON.stringify({
        item_id: item.item_id,
        checkedVariant: item.checkedVariant || [],
        options: item.optionsData || [],
        qty: item.qty,
        amount: item.amount
      });

      // If the uniqueKey does not already exist, add the item
      if (!uniqueItems.has(uniqueKey)) {
        uniqueItems.set(uniqueKey, item);
      }
    });

    // Return only the unique items
    return Array.from(uniqueItems.values());
  }

  // update kot list
  updateKotList(kot_id: any) {
    // Remove the old KOT from the list
    const index = this.kotArr.findIndex(
      (kot: any) => kot.transaction_id === kot_id
    );
    if (index !== -1) {
      this.kotArr.splice(index, 1);
    }
  }

  // all multilogin using toggle button for customer website
  onAllowLogin(allowLogin: boolean) {
    this.allow_login = allowLogin; // Update the local state
    this.apiService
      .updateSessionAllowLogin(this.allow_login, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("data updated successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => { }
      );
  }

  // on category clicked
  showitems(data: any) {

    // initializing the array
    this.items_data = [];
    this.itemArr = [];
    this.sub_category_data = [];

    this.selectedCategoryName = data.category_name;
    if (data.hassubcategory) {
      const cachedSubCategory = this.cacheService.get(
        `subCat-${data.category_id}`
      );

      if (cachedSubCategory) {
        this.sub_category_data = cachedSubCategory;
      } else {
        this.apiService.menuSubCategoryList(data.category_id).subscribe(
          (result: any) => {
            this.sub_category_data = result;
            this.cacheService.set(`subCat-${data.category_id}`, result);
          },
          (result: any) => {
            notyf.error(result.message);
          }
        );
      }
    } else {
      const cachedItems = this.cacheService.get(
        `cat-${data.category_id}-${this.hasPriceList}`
      );

      if (cachedItems) {
        this.items_data = cachedItems;
        this.itemArr = cachedItems;
      } else {
        this.apiService
          .menuItemListByCategory(data.category_id, this.table_id)
          .subscribe(
            (result: any) => {
              this.items_data = result;
              this.itemArr = result;
              this.cacheService.set(
                `cat-${data.category_id}-${this.hasPriceList}`,
                result
              );
            },
            (result: any) => {
              notyf.error(result.message);
            }
          );
      }
    }
  }

  // on sub category clicked
  showSubitems(sub_category_id: any) {
    const cachedItems = this.cacheService.get(
      `cat-${sub_category_id}-${this.table_id}`
    );

    if (cachedItems) {
      this.items_data = cachedItems;
      this.itemArr = cachedItems;
    } else {
      this.apiService
        .menuItemListBySubCategory(sub_category_id, this.table_id)
        .subscribe(
          (result: any) => {
            this.items_data = result;
            this.itemArr = result;
            this.cacheService.set(
              `cat-${sub_category_id}-${this.table_id}`,
              result
            );
          },
          (result: any) => {
            notyf.error(result.message);
          }
        );
    }
  }

  // on add instruction from take away
  instructiondataTakeaway(item: any, n: any) {
    this.itemArr = this.itemArr.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  // on tab change from right pannel
  onChange(event: any) {

    this.order_tab = event.tab.textLabel;

    if (this.order_tab === "Take Away") {
     
      this.order_tab = "Take Away";
      // this.resetData();
      notyf.success("Switched to Take Away");
      // }
    } else if (this.order_tab === "Dine-In") {
      this.order_tab = "Dine-In";
      notyf.success("Switched to Dine-In");
    }
    this.load();
  }

  // Method to handle KOT creation and invoice enforcement
  addItems(item: any) {
    if (this.order_tab === "Take Away") {
      this.addItemToTakeAway(item);
    } else if (this.order_tab === "Dine-In") {
      this.addItemToDineIn(item);
    }

    // this.calculateAmtWithTax();
    this.itemControl.reset();
  }

  // add items to take away
  addItemToTakeAway(item: any) {
    const existingItemIndex = this.itemsArr.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.checkedVariant &&
        item.checkedVariant.length > 0 &&
        item.checkedVariant[0].variants
      ) {
        isVariantsMatch = item.checkedVariant[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.checkedVariant &&
              search_item.checkedVariant.length > 0 &&
              search_item.checkedVariant[0].variants &&
              search_item.checkedVariant[0].variants[index] &&
              search_item.checkedVariant[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });
    let updatedTitle = this.updateItemTitle(item);

    if (existingItemIndex === -1) {
      item.qty = item.qty || 1;
      item.amount = this.calculateItemAmount(item);
      // item.item_code = updatedTitle;

      this.itemsArr.push({ ...item });
    } else {
      this.itemsArr[existingItemIndex].qty += item.qty || 1;
      this.itemsArr[existingItemIndex].amount = this.calculateItemAmount(
        this.itemsArr[existingItemIndex]
      );
    }
    this.resetItemInput();
    this.sitm.nativeElement.focus();
  }

  updateItemTitle(item: any) {
    let newTitle = item.item_code; // Start with the original item name

    // Check for any checked variants
    if (item.checkedVariant && item.checkedVariant.length > 0) {
      item.checkedVariant.forEach((variantGroup: any) => {
        if (
          variantGroup.ischecked ||
          variantGroup.checked ||
          variantGroup.wera_active
        ) {
          newTitle += ` - ${variantGroup.title}`; // Append the parent variant group title if checked

          variantGroup.variants.forEach((variant: any) => {
            if (
              variant.ischecked ||
              variant.checked ||
              variantGroup.wera_active
            ) {
              newTitle += ` - ${variant.title}`; // Append the variant title if checked
            }
          });
        }
      });
    }

    // Check for any checked options
    if (item.optionsData && item.optionsData.length > 0) {
      item.optionsData.forEach((option: any) => {
        if (option.ischecked || option.checked || option.wera_active) {
          newTitle += ` - ${option.title}`; // Append option title if checked
        }

        // Handle child options, if available and checked
        if (option.childOptions && option.childOptions.length > 0) {
          option.childOptions.forEach((childOption: any) => {
            if (
              childOption.ischecked ||
              childOption.checked ||
              childOption.wera_active
            ) {
              newTitle += ` - ${childOption.title}`; // Append child option title if checked
            }
          });
        }
      });
    }

    return newTitle;
  }


  // calculate item amount

  addItemToDineIn(item: any) {

    let updatedTitle = this.updateItemTitle(item);

    const existingItemIndex = this.KOT_data.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
              childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.variant_groups &&
        item.variant_groups.length > 0 &&
        item.variant_groups[0].variants
      ) {
        isVariantsMatch = item.variant_groups[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.variant_groups &&
              search_item.variant_groups.length > 0 &&
              search_item.variant_groups[0].variants &&
              search_item.variant_groups[0].variants[index] &&
              search_item.variant_groups[0].variants[index].title ===
              variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    if (existingItemIndex === -1) {
      item.qty = item.qty || 1;
      item.amount = this.calculateItemAmount(item);
      this.KOT_data.push({ ...item });

    } else {
      this.KOT_data[existingItemIndex].qty += item.qty || 1;
      this.KOT_data[existingItemIndex].amount = this.calculateItemAmount(
        this.KOT_data[existingItemIndex]
      );
    }
  }

  calculateItemAmount(item: any) {
    const baseAmount = item.qty * item.s_rate;
    const optionsAmount = item.qty * this.optionarray_amount;
    return baseAmount + optionsAmount;
  }


  //on tab change from right pannel  like take away and dine in
  onChange1(event: any) {
    this.order_tab = event.tab.textLabel;
    this.KOT_data = [];
    if (this.order_tab === "Take Away") {
      notyf.success("Switched to Take Away");
    } else {
      notyf.success("Switched to Dine-In");
    }
  }

  addItemsone(item: any) {

    if (this.order_tab === "Dine-In") {

      this.apiService.addItemToDineInCart(item);
    }

    if (this.order_tab === "Take Away") {

      this.apiService.addItemToTakeAwayCart(item);
    }

  }

  openCustomization(e: any) {

    if (this.order_tab === "Dine-In") {
      this.apiService.addItemToDineInCart(e);
    }
  
    if (this.order_tab === "Take Away") {
      this.apiService.addItemToTakeAwayCart(e);
    }
    this.resetItemInput();
  }

  addItemCardClicked(item: any) {

    if (
      (item.options && item.options.length) > 0 ||
      (item.variant_groups && item.variant_groups.length) > 0
    ) {
      // const data = { item: this.itemObj[indexofItem], status: "add" };
      this.openCustomizationByCard(item);
    } else {
      // Increment qty on each click
      const isCustomised = false;
      this.addItemsone(item);
    }
  }

  // to open item customization modal
  openCustomizationByCard(e: any) {

    if (this.order_tab === "Dine-In") {
      this.apiService.addItemToDineInCart(e);
    }

    if (this.order_tab === "Take Away") {
      this.apiService.addItemToTakeAwayCart(e);
    }
   
  }
 
  // celar table if there is customer web app active in the table in the table.
  clearTable() {
    this.webappActiveTable = false;
    this.apiService
      .updateActiveTable_inactive(this.webappActiveTable, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("Table Clear successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => { }
      );
  }

  // to open table transfer dialog
  openTransferDialog(): void {
    const dialogRef: MatDialogRef<TabletransferDialogComponent> =
      this.dialog.open(TabletransferDialogComponent, {
        width: "400px",
        data: this.table_id,
      });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "true") {
        // this.initializeFormGroup();
        // this.load();
      }
    });
  }

  // clear water and waiter if any customer called from customer web app
  clearwaterwaiter() {
    this.apiService.updatewaterwaiter(this.table_id).subscribe(
      (result: any) => {
        if (result) {
          notyf.success("Water/waiter Clear successfully");
          // this.load();
        } else {
          notyf.error(result.message);
        }
      },
      (result: any) => { }
    );
  }

  // Updated event handler for Enter key press
  onEnterKey(event: any) {
    // Prevent default behavior and check if the key is Enter
    if (event.key === "Enter") {
      event.preventDefault();

      // If focus is on the search input (sitm.nativeElement)
      if (event.target === this.sitm.nativeElement) {
        // Check if there is a valid item in the filteredOptions list
        this.filteredOptions.subscribe((options) => {
          if (options && options.length === 1) {
            // Set the selected item and focus on the quantity field
            this.itemControl.setValue(options[0]);

            setTimeout(() => {
              if (this.qty1 && this.qty1.nativeElement) {
                this.qty1.nativeElement.focus();
              }
            }, 0);
          }
        });
      } else if (event.target === this.qty1.nativeElement) {
        // If Enter is pressed while in the quantity input, add the item
        this.onAddItem(); // This method will handle adding the item to KOT
      }
    }
  }

  // ======================disposal
  showdisposalitems(data: any) {
    if (Array.isArray(this.disposalItemData)) {
      this.disposalItemlist = this.disposalItemData.filter((e: any) => {
        return e.category_id === data;
      });
    } else {
      console.error('disposalItemData is not defined or not an array');
      // Optionally, you can initialize disposalItemData here if necessary
      this.disposalItemlist = [];
    }
  }

  // Increment the quantity
  incrementQty() {

    // Get the current value from the form control, or default to 0 if it is empty/invalid
    const currentQty = parseFloat(this.form.get("item_qty")?.value) || 0;
    let newQty = 0;

    // Check if the current value is the minimum value (0.1), if so, increment by 0.9 to make it 1
    if (currentQty === 0.1) {
      newQty = currentQty + 0.9;
    } else {
      // For all other values, increment by 1
      newQty = currentQty + 1;
    }

    // Set the new quantity value in the form control and ensure it's to 1 decimal place
    this.form.get("item_qty")?.setValue(newQty.toFixed(1));

    // Trigger the recalculation of the item amount
    this.calculateItemAmt({ target: { value: newQty } });
  }

  // Decrement the quantity
  decrementQty() {
    let newQty = 0;
    let subtractValue = 1;
    const currentQty = parseFloat(this.form.get("item_qty")?.value) || 0; // Get the current value or default to 0
    if (currentQty <= 0.1) {
      // Prevent going below zero
      newQty = 0.1;
    } else if (currentQty === 1) {
      newQty = currentQty - 0.9;
    } else if (currentQty < 1) {
      // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
      newQty = currentQty - subtractValue;
      if (newQty <= 0.0 || newQty <= 0) {
        newQty = 0.1;
      }
    } else {
      newQty = currentQty - 1; // Decrement by 1
    }
    this.form.get("item_qty")?.setValue(newQty.toFixed(1)); // Set the new value with one decimal place
    this.calculateItemAmt({ target: { value: newQty } }); // Trigger any recalculations
  }

  calculateItemAmt(event: any) {

    const sitmInputValue = this.itemControl?.value;

    if (!sitmInputValue) {
      // this.form.get("item_qty")!.setValue(0);
      return;
    }

    const s_rate = this.form.value.item_rate;
    const s_Rate = parseFloat(s_rate);

    let qty = this.form?.value.item_qty;
    if (typeof qty == "string") {
      qty = parseFloat(this.form?.value.item_qty);
    }

    if (parseFloat(qty) <= 0 || qty === "" || isNaN(qty)) {
      qty = 0.0;
    }

    let amount = (qty ? qty : 0) * (s_Rate ? s_Rate : 0);

    this.form.get("item_amt")!.setValue(amount);
    this.model.qty = parseFloat(qty);
    this.model.s_rate = (s_rate != null && !isNaN(s_rate)) ? s_rate : null;
    this.model.amount = amount;
    this.form.value.item_qty = this.model.qty;
    if (event.key === "Enter" && !this.isQtyFocused) {
      this.onAddItem();
      this.sitm.nativeElement.focus();
    }

    if (event.key === "Enter" && this.isQtyFocused) {
      // this.s_rate1.nativeElement.focus();
    }

    const rate = this.form.get("item_rate")?.value || 0;
    // const amount = qty * rate;
    this.form.get("item_amt")?.setValue(amount);
  }


  setInputValue(value: string): void {
    this.inputValue = value;
  }


  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey && event.key === "o") {
      this.togglePopup();
      event.preventDefault();
      setTimeout(() => {
        const searchInput = document.getElementById("popup-search-input");
        searchInput?.focus();
      }, 100);
    } else if (event.ctrlKey && event.altKey && event.key === "m") {
      this.moveToKotList();
      event.preventDefault();
    } else if (event.key === "Escape") {
      this.closePopup();
      event.preventDefault();
    }
  }

  togglePopup() {
    this.showPopup = !this.showPopup;
    if (this.showPopup) {
      setTimeout(() => {
        const searchInput = document.getElementById("popup-search-input");
        searchInput?.focus();
      }, 100);
    }
  }

  searchItems(event: any) {
    const query = event.target?.value.toLowerCase();
    this.filteredOptions = of(this.filterItems(query));
  }

  filterItems(query: string): any[] {
    return this.itemsArr.filter((item: any) =>
      item.item_name.toLowerCase().includes(query)
    );
  }

  selectItem(event: MatAutocompleteSelectedEvent) {
    const selectedItem = event?.option?.value;
    this.addItemToOrderList(selectedItem);
    setTimeout(() => {
      const searchInput = document.getElementById("popup-search-input");
      searchInput?.focus();
    }, 100);
  }

  addItemToOrderList(item: any) {
    const qty = this.form.get("item_qty")?.value || 1;
    const rate = this.form.get("item_rate")?.value || 0;
    const amount = rate * qty;
    this.orderList.push({ ...item, qty, amount });
    this.form.reset({ item_qty: 1, item_rate: 0, item_amt: 0 });
  }

  moveToKotList() {
    this.kotList.push(...this.orderList);
    this.orderList = [];
    this.showPopup = false;
  }

  closePopup(event?: MouseEvent) {
    if (event) {
      const target = event.target as HTMLElement;
      if (!target.closest(".popup-content")) {
        this.showPopup = false;
      }
    } else {
      this.showPopup = false;
    }
  }

}
